import React from 'react';
import './EventPage.css';

const eventInfo = [
    {
        title: 'Free Board Gaming Event',
        location: 'Wimberley Community Center',
        locationMapLink: 'https://www.google.com/maps/place/Wimberley+Community+Center/@29.9988421,-98.1013384,17z/data=!3m1!4b1!4m6!3m5!1s0x865b5e79a45c77bf:0x82a9391ef9ef59a2!8m2!3d29.9988375!4d-98.0987635!16s%2Fg%2F1tgm38lr?entry=ttu&g_ep=EgoyMDI0MTEwNi4wIKXMDSoASAFQAw%3D%3D',
        date: 'October 12th, 2024 - open 12-6pm',
        timeNote: '(Come anytime and stay as long as you want!)',
        description: 'Step into Super Dimension&apos;s Pop-Up Portal and experience a variety of board games from our library for free! Bring your friends or come looking to meet new people.',
        bulletPoints: [
            'Games Gurus available to teach & setup games for you.',
            'Over 50 free games to try from our growing game library!',
            'Open to all ages and experience levels.',
            'Come anytime between 12-6pm and stay for as long as you want.',
            'Free entry, free treats and free things to find!'
        ],
        gamesTitle: 'Lots of games available like:',
        gamesList: [
            'Catan',
            'Heat: Pedal to the Metal',
            'Unmatched',
            'Codenames',
            'Betrayal at House on the Hill',
            'Ticket to Ride',
            'Wingspan',
            'And so much more!'
        ],
        flyer: 'PopUpPortalFlyer-Catan.png'
    },
    {
        title: 'Free Board Gaming Event',
        location: 'Wimberley Village Library',
        locationMapLink: 'https://www.google.com/maps/place/Wimberley+Village+Library/@30.0026062,-98.1065254,17z/data=!3m1!4b1!4m6!3m5!1s0x865b5dd8902f7aef:0xd2d2117fe1f437a0!8m2!3d30.0026016!4d-98.1039505!16s%2Fm%2F0jb4tw7?entry=ttu&g_ep=EgoyMDI0MTEwNi4wIKXMDSoASAFQAw%3D%3D',
        date: 'Novemeber 16th, 2024 - open 12-5pm',
        timeNote: '(Come anytime and stay as long as you want!)',
        description: 'Step into Super Dimension&apos;s Pop-Up Portal and experience a variety of board games from our library for free! Bring your friends or come looking to meet new people.',
        bulletPoints: [
            'Games Gurus available to teach & setup games for you.',
            'Over 50 free games to try from our growing game library!',
            'Open to all ages and experience levels.',
            'Come anytime between 12-5pm and stay for as long as you want.',
            'Free entry, free treats and free things to find!'
        ],
        gamesTitle: 'Lots of games available like:',
        gamesList: [
            'Catan',
            'Heat: Pedal to the Metal',
            'Unmatched',
            'Codenames',
            'Betrayal at House on the Hill',
            'Ticket to Ride',
            'Wingspan',
            'And so much more!'
        ],
        flyer: 'event_flyers/PopUp - Carcasssone.png'
    },
    {
        title: 'Free Board Gaming Event',
        location: 'Wimberley Blanco River Academy',
        locationMapLink: 'https://www.google.com/maps/place/Blanco+River+Academy/@29.9996932,-98.0921123,17z/data=!3m1!4b1!4m6!3m5!1s0x865b5b8d944a8599:0x84e9776db1812461!8m2!3d29.9996886!4d-98.0895374!16s%2Fg%2F11c5t1nn6z?entry=ttu&g_ep=EgoyMDI0MTEwNi4wIKXMDSoASAFQAw%3D%3D',
        date: 'December 14th, 2024 - open 12-7pm',
        timeNote: '(Come anytime and stay as long as you want!)',
        description: 'Step into Super Dimension&apos;s Pop-Up Portal and experience a variety of board games from our library for free! Bring your friends or come looking to meet new people.',
        bulletPoints: [
            'Games Gurus available to teach & setup games for you.',
            'Over 50 free games to try from our growing game library!',
            'Open to all ages and experience levels.',
            'Come anytime between 12-7pm and stay for as long as you want.',
            'Free entry, free treats and free things to find!'
        ],
        gamesTitle: 'Lots of games available like:',
        gamesList: [
            'Catan',
            'Heat: Pedal to the Metal',
            'Unmatched',
            'Codenames',
            'Betrayal at House on the Hill',
            'Ticket to Ride',
            'Wingspan',
            'And so much more!'
        ],
        flyer: 'event_flyers/PopUpBRAC_Dixit.png'
    },
    {
        title: 'Free Board Gaming Event',
        location: 'Wimberley Community Center',
        locationMapLink: 'https://www.google.com/maps/place/Wimberley+Community+Center/@29.9988421,-98.1013384,17z/data=!3m1!4b1!4m6!3m5!1s0x865b5e79a45c77bf:0x82a9391ef9ef59a2!8m2!3d29.9988375!4d-98.0987635!16s%2Fg%2F1tgm38lr?entry=ttu&g_ep=EgoyMDI0MTEwNi4wIKXMDSoASAFQAw%3D%3D',
        date: 'January 18th, 2025 - open 12-7pm',
        timeNote: '(Come anytime and stay as long as you want!)',
        description: 'Step into Super Dimension&apos;s Pop-Up Portal and experience a variety of board games from our library for free! Bring your friends or come looking to meet new people.',
        bulletPoints: [
            'Games Gurus available to teach & setup games for you.',
            'Over 50 free games to try from our growing game library!',
            'Open to all ages and experience levels.',
            'Come anytime between 12-7pm and stay for as long as you want.',
            'Free entry, free treats and free things to find!'
        ],
        gamesTitle: 'Lots of games available like:',
        gamesList: [
            'Catan',
            'Heat: Pedal to the Metal',
            'Unmatched',
            'Codenames',
            'Betrayal at House on the Hill',
            'Ticket to Ride',
            'Wingspan',
            'And so much more!'
        ],
        flyer: 'event_flyers/Board Game flyer (Catan) - parks and rec.png'
    },
    {
        title: 'Free Valentine\'s Board Gaming Event',
        location: 'Wimberley Community Center',
        locationMapLink: 'https://www.google.com/maps/place/Wimberley+Community+Center/@29.9988421,-98.1013384,17z/data=!3m1!4b1!4m6!3m5!1s0x865b5e79a45c77bf:0x82a9391ef9ef59a2!8m2!3d29.9988375!4d-98.0987635!16s%2Fg%2F1tgm38lr?entry=ttu&g_ep=EgoyMDI0MTEwNi4wIKXMDSoASAFQAw%3D%3D',
        date: 'February 15th, 2025 - open 12-7pm',
        timeNote: '(Come anytime and stay as long as you want!)',
        description: 'Step into Super Dimension&apos;s Pop-Up Portal and experience a variety of board games from our library for free! Bring your friends or come looking to meet new people.',
        bulletPoints: [
            'Games Gurus available to teach & setup games for you.',
            'Over 50 free games to try from our growing game library!',
            'Open to all ages and experience levels.',
            'Come anytime between 12-7pm and stay for as long as you want.',
            'Free entry and themed treats!',
            'Donations are welcome.'
        ],
        gamesTitle: 'Lots of games available like:',
        gamesList: [
            'Catan',
            'Heat: Pedal to the Metal',
            'Unmatched',
            'Codenames',
            'Betrayal at House on the Hill',
            'Ticket to Ride',
            'Wingspan',
            'And so much more!'
        ],
        flyer: 'event_flyers/Board Game flyer (Catan) - parks and rec.png'
    }
]

const upcomingEventNumbers = [3,4];
const upcomingNextEvent = 5;

const createEventBlock = (currentEvent: number) => {
    return(
        <div className="eventPageBlock">
            <div className="flyerImageBlock">
                <img 
                    src={eventInfo[currentEvent].flyer} 
                    className="flyerImage" 
                    alt='Free Board Gaming Event Flyer'
                />
            </div>
            <div className="eventInfoBlock">
                <h1 className="eventTitle">
                    {eventInfo[currentEvent].title}
                </h1>
                <a href={eventInfo[currentEvent].locationMapLink}>
                    <h3 className="eventLocation">
                        {eventInfo[currentEvent].location}
                    </h3>
                </a>
                <h3 className="eventDateTime">
                    {eventInfo[currentEvent].date}
                </h3>
                <p className="eventTimeNote">{eventInfo[currentEvent].timeNote}</p>
                <p className="eventDescription">
                    {eventInfo[currentEvent].description}
                </p>
                <ul className="eventBulletPoints">
                    {eventInfo[currentEvent].bulletPoints.map( bullet => {return <li>{bullet}</li>} )}
                </ul>
                <p className="eventDescription">
                    {eventInfo[currentEvent].gamesTitle}
                </p>
                <ul className="eventBulletPoints">
                    {eventInfo[currentEvent].gamesList.map( bullet => {return <li>{bullet}</li>} )}
                </ul>
            </div>
        </div>
    )
}

const EventPage = () => {

    // return upcomingEventNumbers.map(eventNumber => {
    //     return createEventBlock(eventNumber-1);
    // })

    return createEventBlock(upcomingNextEvent-1);
}

export default EventPage;